<template>
    <v-app-bar>
        <div class="ml-5 mr-5">
            <router-link to="/" custom v-slot="{ navigate }">
                <button @click="navigate" role="link">
                    <v-row no-gutters class="align-center pt-2">
                        <v-img :src="require('@/assets/logo-landscape-light.png')" :width="140" :height="70"></v-img>
                    </v-row>
                </button>
            </router-link>
        </div>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" rounded="xl" color="#EE8254" class="mr-5 pl-10 pr-10 d-none d-md-flex" to="/contact">
            {{ $t('CONTACT') }}
        </v-btn>
        <v-btn variant="flat" rounded="xl" color="#EE8254" class="mr-5 pl-10 pr-10 d-none d-md-flex" @click="signIn()">
            {{ $t('SIGN_IN') }}
        </v-btn>
        <v-menu width="200" theme="dark" :right="true">
      <template v-slot:activator="{ props }">
        <v-btn class="d-flex d-md-none" color="#EE8254" v-bind="props" icon="mdi-menu"></v-btn>
      </template>
      <v-list>
        <v-list-item to="/contact">
          <v-list-item-title>{{ $t('CONTACT') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signIn()">
          <v-list-item-title>{{ $t('SIGN_IN') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
</template>

<script>
export default {
    name: "AppBar",
    computed: {
        baseUrl() {
            return process.env.VUE_APP_CONSOLE_BASE_URL;
        }
    },
    methods: {
        contactUs() {
            this.$router.push("contact");
        },
        signIn() {
            window.open(this.baseUrl + "signin", '_self');
        }
        
    }
};
</script>

<style scoped>


</style>