export default {
  "HEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn your Projects into Qualified Proposals"])},
  "POST_YOUR_PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Your Project Today"])},
  "HOW_IT_WORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How It Works"])},
  "WHY_CONNXIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why ConnXions?"])},
  "READY_FOR_SMARTER_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Experience a Smarter Service Provider Sourcing Process?"])},
  "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "BOOK_DEMO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a Demo"])},
  "WHO_WE_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who We Help"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "SIGN_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "SUCESSFUL_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your file(s) have been uploaded successfully"])},
  "LETS_TALK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's talk about it. What can we answer for you today?"])},
  "SERVICE_PROVIDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Provider"])},
  "SERVICE_PROVIDER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I work for a company that specializes in IT professionals for project based work"])},
  "ENTERPRISE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Clients"])},
  "ENTERPRISE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I work for a company that is looking to procure a professional services partner"])},
  "PERSPECTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perspective"])},
  "PREFERRED_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Name"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
  "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "BUSINESS_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Email"])},
  "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about your needs"])},
  "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "SIGN_UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "THANKS_FOR_YOUR_INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest in Connxions. Please check the email you used to register to continue the sign up process"])}
}