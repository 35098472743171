<template>
  <v-card variant="flat" color="#263238" class="py-16">
    <v-container>
      <v-card variant="tonal" class="mx-auto" max-width="1000">
        <v-img src="@/assets/how-it-works.gif" cover></v-img>
      </v-card>
      <v-card variant="flat" class="py-12 text-center" color="transparent">
        <v-row>
          <v-col cols="12" class="text-center">
            <p class="font-weight-bold text-h4" style="color: #2979FF;">{{$t('HOW_IT_WORKS')}}</p>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="auto" v-for="(step, index) in steps" :key="index">
            <v-card variant="tonal" class="d-flex align-center text-center" max-width="300" width="300" max-height="200"
              height="200">
              <v-card-text>
                <p class="font-weight-bold text-h5 mb-2">{{ step.title }}</p>
                <p>{{ step.text }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-card>
</template>
  
  <script>
  export default {
    data() {
      return {
        steps: [
          { title: "Upload Project Documents", text: "Upload your project documents to our platform and let our AI Engine do the rest." },
          { title: "Get Matched Instantly", text: "We source the top, vetted IT service providers tailored to your needs." },
          { title: "Receive, Compare, & Select", text: "Receive side-by-side proposals and choose the best provider-all in one platform." }
        ]
      };
    }
  };
  </script>