<template>
  <v-card variant="flat" class="py-12">
    <v-container>
        
        <v-row dense class="justify-center">
          <v-col cols="auto">
            <v-card variant="flat" min-width="400" max-width="400">
              <v-card-title class="font-weight-bold text-h4" style="color: #2979FF;">{{$t('WHY_CONNXIONS')}}</v-card-title>
              <v-list>
                <v-list-item v-for="(reason, index) in reasons" :key="index">
                  <v-list-item-title>{{ reason.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ reason.subtitle }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card variant="outlined" width="400">
              <v-img class="bg-grey-lighten-2" src="@/assets/proposal-assessment.png" cover></v-img>
            </v-card>
          </v-col>
        </v-row>
    </v-container>
  </v-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        reasons: [
          { title : "Lightning-fast project sourcing", subtitle : "Post once, get qualified proposals quickly"},
          { title : "Simplified procurement", subtitle : "Save time, cut through the noise, and get results"},
          { title : "Data driven decisions", subtitle : "Advised with average cost & time of similar projects"},
          { title : "Side-by-side proposal comparison", subtitle : "Better understand your options"},
          { title : "AI proposal scoring", subtitle : "Make smarter, more confident decisions"},
          { title : "Expanded your network of service providers", subtitle : "Go beyond your usual vendor list"}
        ]
      };
    }
  };
  </script>