import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import FileUpload from '../views/FileUpload.vue'
import ContactUs from '../views/ContactUs.vue'
import TryNow from '../views/TryNow.vue'

const routes = [
  {
    path: "/",
    name: "welcome",
    component: Welcome,
  },
  {
    path: "/file-upload",
    name: "file-upload",
    component: FileUpload,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactUs,
  },
  {
    path: "/register",
    name: "register",
    component: TryNow,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/not-found",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router