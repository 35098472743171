<template>
  <v-card variant="flat" class="mb-16 ml-5 mr-5">
    <v-row dense>
      <v-col cols="12" class="text-center">
        <p class="font-weight-bold text-h4" style="color: #2979FF;">{{$t('WHO_WE_HELP')}}</p>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="auto" v-for="(section, index) in helpSections" :key="index">
        <v-card variant="outlined" class="p-12 bg-gray-100 text-center" max-width="400" height="300">
          <v-card-text class="text-h6">{{ section.title }}</v-card-text>
          <v-card-text>{{ section.text }}</v-card-text>
          <v-card-text>
          <v-chip variant="outlined" :to="section.href" label size="large" class="py-5 px-10" color="#2979FF">
        {{ section.label }}
        <v-icon icon="mdi-arrow-right" end></v-icon>
      </v-chip></v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      helpSections: [
        { title: "For Project Owners", text: "Tired of the slow, painful and outdated way of sourcing IT service providers? ConnXions makes it simple—post your project once, and we’ll deliver a shortlist of pre-vetted, enterprise-ready experts to choose from.", label: "Post Your Project Today", href: "/file-upload" },
        { title: "For Technology Service Providers", text: "Getting into enterprise IT contracts is hard. ConnXions opens doors by putting your services directly in front of companies looking for expertise. Skip the cold outreach—just focus on winning the work.", label: "Create Company Profile", href: "/register" }
      ]
    };
  }
};
</script>