<template>
    <v-sheet>
        <section id="hero-section">
            <hero-section></hero-section>
        </section>"
        <section id="how-it-works">
            <how-it-works></how-it-works>
        </section>
        <section id="why-connxions">
            <why-connxions></why-connxions>
        </section>
        <section id="who-we-help">
            <who-we-help></who-we-help>
        </section>
        <section id="client-testimonials">
            <client-testimonials></client-testimonials>
        </section>
        <section id="call-to-action">
            <call-to-action></call-to-action>
        </section>"
        <section id="footer">
            <app-footer></app-footer>
        </section>
    </v-sheet>
</template>
<script setup>
import HeroSection from '@/components/HeroSection.vue';
import HowItWorks from '@/components/HowItWorks.vue';
import WhyConnxions from '@/components/WhyConnxions.vue';
import WhoWeHelp from '@/components/WhoWeHelp.vue';
import CallToAction from '@/components/CallToAction.vue';
import ClientTestimonials from '@/components/ClientTestimonials.vue';
import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "WelcomePage",
    data() {
        return {
        };
    }
};
</script>

<style>
#footer {
    height: 10vh;
}
</style>