<template>
    <v-card variant="flat" class="mt-16 mb-16 ml-5 mr-5">
      <v-row dense>
        <v-col class="text-center">
          <p class="font-weight-bold text-h4" style="color: #2979FF;">{{ $t('READY_FOR_SMARTER_PROCESS') }}</p><br>
          <v-chip color="#2979ff" variant="outlined" to="/file-upload" label size="large" class="py-5 px-10  mb-3">
            {{ $t('POST_YOUR_PROJECT') }}
        <v-icon icon="mdi-arrow-right" end></v-icon>
      </v-chip>
      &emsp;
      <v-chip color="#2979ff" href="https://calendly.com/customersupport-connxions/demo" target="_blank" variant="outlined" label size="large" class="py-5 px-10  mb-3">
        {{ $t('BOOK_DEMO') }}
        <v-icon icon="mdi-open-in-new" end></v-icon>
      </v-chip>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            
        };
    },
    methods: {

    }
}
</script>
