<template>
    <v-container>
        <v-snackbar v-model="success" location="top" timeout="3000" width="800" rounded color="success">
            {{ $t('SUCESSFUL_UPLOAD') }}
            <template v-slot:actions>
                <v-btn variant="text" @click="success = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="error" location="top" timeout="3000" width="800" rounded color="error">
            {{ errorDescription }}
            <template v-slot:actions>
                <v-btn variant="text" @click="success = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-card variant="flat" class="mx-auto mt-16 rounded-lg mb-3" max-width="600">
            <v-card-title>
                To initiate the process, please submit:
            </v-card-title>
            <v-card-text>
                <v-list-item>
                    <v-list-item-title class="text-body-1">
                        1. Your project documents:
                    </v-list-item-title>
                    <v-list-item-title class="text-body-1">
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#x2022; Request for Proposal (RFP)<br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#x2022; Request for Quotation (RFQ)<br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#x2022; Request for Information (RFI)<br>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title class="text-body-1">
                        2. Accompanying agreement:
                    </v-list-item-title>
                    <v-list-item-title class="text-body-1">
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#x2022; Professional Services Agreement (PSA)<br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#x2022; Consulting Services Agreement<br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#x2022; Master Services Agreement (MSA)<br>
                    </v-list-item-title>
                </v-list-item>
            </v-card-text>
            <v-file-upload v-model="files" clearable multiple show-size scrim="primary" browse-text="Browse Files"
                divider-text="or" title="Drag and drop files here" icon="mdi-upload"></v-file-upload>
            <v-btn class="text-none text-subtitle-1" size="x-large" color="primary" variant="flat" rounded="lg" block
                v-show="files.length > 0" @click="upload()">Upload</v-btn>
        </v-card>
    </v-container>
</template>

<script>
  export default {
    name: "FileUpload",
    data() {
        return {
            success : false,
            error: false,
            errorDescription: "",
            files: []
        }
    },
    computed: {
        api() {
            return process.env.VUE_APP_API_ENDPOINT + '/files/public';
        }
    },
    methods: {
        upload() {
            this.axios.post(this.api, this.files, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.success = true;
                this.files = [];
            }).catch((error) => {
                this.error = true;
                this.errorDescription = error.response.data.errorDescription;
            });
        }
    }
  };
</script>