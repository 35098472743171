<template>
    <footer>
        <v-img src="@/assets/logo-landscape-dark.png" height="50" width="130" class="center mb-1" />
        <a href="https://www.linkedin.com/company/connxions" target="_blank"><v-img src="@/assets/linkedin.webp" height="25" width="25" class="center mt-2 mb-2" /></a>
        <p class="white--text">&copy;&nbsp;{{ new Date().getFullYear() }} — <strong>{{ this.companyName }}</strong></p>
    </footer>
</template>

<script>
export default {
    name: "AppFooter",
    computed: {
        companyName() {
            return process.env.VUE_APP_COMPANY_NAME;
        }
    },
};
</script>

<style>
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

footer {
    background-color: #263238;
    color: white;
    text-align: center;
    padding: 20px 0;
    width: 100%;
}
</style>