<template>
     <v-container class="pa-0">
      <v-card variant="flat" class="d-flex align-center text-center rounded-0" min-height="500" min-width="300">
        <v-card-text>
          <p class="font-weight-bold text-h3" style="color: #2979FF;">{{$t('HEADLINE')}}</p>
          <p class="mt-4 text-h5">
            Automate your outdated project procurement process with our <strong>AI Powered Decision Engine</strong>.<br>Your AI Assistant
            for IT Procurement.
          </p>
          <v-chip color="#2979ff" variant="flat" to="/file-upload" size="large"
            class="mt-6 pa-5 font-semibold shadow-md hover:bg-[#d76b45]">
            {{ $t('POST_YOUR_PROJECT') }}
            <v-icon icon="mdi-arrow-right" end></v-icon>
          </v-chip>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
      };
    }
  };
  </script>