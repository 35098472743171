import { createApp } from 'vue'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { VueHeadMixin, createHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { VFileUpload } from 'vuetify/labs/VFileUpload'

import App from './App.vue'
import router from './router'
import i18n from './i18n'

import axios from 'axios'
import VueAxios from 'vue-axios'

import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css'

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

const vuetify = createVuetify({
  directives,
  components,
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    defaultTheme: 'light'
  },
  icons: {
    defaultSet: 'mdi',
  },
});

createApp(App)
  .use(i18n)
  .use(vuetify)
  .use(router)
  .component('VFileUpload', VFileUpload)
  .use(VueAxios, axios)
  .mixin(VueHeadMixin)
  .mount("#app");

createHead()